import {
    Accordion,
    AccordionItem,
} from '@carbon/react';
import TrialCard from './basic/TrialCard';

function ResponseForFindTrials({ llmRes, webChatInstance }) {

    const enhancedTrials = llmRes.trials.map((trial, index) => {
        return {
            ...trial,
            id: index + 1
        };
    });

    return <>
        <br />
        <Accordion>
            {
                enhancedTrials.map((trial, index) => {
                    return <AccordionItem title={`${trial['nct']} (${trial['status']})`} open={true}>
                        <TrialCard trial={trial} webChatInstance={webChatInstance} />
                    </AccordionItem>
                })
            }
        </Accordion>
    </>
}


export default ResponseForFindTrials;